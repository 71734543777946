








import { Component, Vue, Watch } from 'vue-property-decorator';
import Card from '@/components/card/Card.vue';
import Page from '@/components/Page.vue';
import { PortalService } from '@/modules/portal/services/PortalService';
import { PortalZone } from '@/modules/portal/domain/PortalZone';
import StationsTable from '../components/StationsTable.vue';
import { Portal } from '@/modules/portal/domain/Portal';

@Component({
  components: {Page, Card, StationsTable},
})
export default class StationsListing extends Vue {
  private isLoading: boolean = false;
  private zones: PortalZone[] | PortalZone[] = [];
  private portals: Portal[] | Portal[] = [];

  private async mounted() {
    await this.fetchStations();
  }

  private async fetchStations() {
    this.isLoading = true;
    try {
      this.portals = await PortalService.getAll(this.$store.getters['sessionStore/token']);
      this.zones = await PortalService.getZonesForUser(this.$store.getters['sessionStore/token']);
    } catch {
      this.$notify.error({
        title: 'ERREUR',
        message: 'Vos annonceurs n\'ont pas pu être chargés. Veuillez réessayer.',
      });
    }
    this.isLoading = false;
  }


}
