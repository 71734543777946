





















import { Component, Vue, Prop } from 'vue-property-decorator';
import { StationsListingData } from '../domain/StationsListingData';
import { PortalZone } from '@/modules/portal/domain/PortalZone';
import { Portal } from '@/modules/portal/domain/Portal';
import { PortalListingData } from '@/modules/portal/domain/PortalListingData';

@Component
export default class StationsTable extends Vue {
  @Prop() private readonly zones!: StationsListingData[];
  @Prop() private readonly portals!: PortalListingData[];
  @Prop() private readonly isLoading!: boolean;

  private showStationCampaign(station: PortalZone) {
    this.$router.push('/view-station/' + station.code);
  }
}
