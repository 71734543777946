





import {Component, Vue, Watch, Prop} from 'vue-property-decorator';

@Component
export default class Page extends Vue {}
