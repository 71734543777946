












import {Component, Prop, Vue} from 'vue-property-decorator';
import CardTitle from './CardTitle.vue';

@Component({
  components: {CardTitle},
})
export default class Card extends Vue {
  @Prop() private readonly title!: string;
  @Prop() private readonly error!: string;
  @Prop() private readonly isLoading!: boolean;
  @Prop() private readonly hasError!: boolean;
}
