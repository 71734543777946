import { ApiInstance } from '@/api/ApiInstance';
import { configuration } from '@/config';
import { PortalSearchCriteria } from '../domain/PortalSearchCriteria';
import { PortalListingResponse } from '../rest/responses/PortalListingResponse';
import { AuthorizationHeaderBuilder } from '@/api/AuthorizationHeaderBuilder';
import { Token } from '@/modules/session/domain/Types';
import { PortalListingData } from '../domain/PortalListingData';
import { PortalZone } from '../domain/PortalZone';
import { Id } from '@/modules/user/domain/Types';
import { Portal } from '../domain/Portal';
// @ts-ignore
import { SavePortalEvent } from '../views/PortalFormView.vue';

export class PortalService {
    private static readonly portalApi = ApiInstance.createFor(`${configuration.GATEWAY_URL}/pubs-neo/portal`);
    private static readonly zoneApi = ApiInstance.createFor(`${configuration.GATEWAY_URL}/pubs-neo/zone`);
    private static readonly userApi = ApiInstance.createFor(`${configuration.GATEWAY_URL}/pubs-neo/user`);

    public static async getAll(token: Token) {
        const { data: response } = await this.portalApi.get<Portal[]>('/all?search=', {
            headers: AuthorizationHeaderBuilder.buildBearerWith(token),
        });

        return response;
    }

    public static async getList(token: Token) {
        const { data: response } = await this.portalApi.get<Portal[]>('/list?search=', {
            headers: AuthorizationHeaderBuilder.buildBearerWith(token),
        });

        return response;
    }

    public static async getPortalBy(id: Id, token: Token) {
        const { data: response } = await this.portalApi
            .get<Portal>(`/${id}`, { headers: AuthorizationHeaderBuilder.buildBearerWith(token) });
        return { name: response.name, target: response.target, zones: response.zones };
    }

    public static async update(portal: SavePortalEvent, token: Token) {
        // Il faut sauvegarder les nouvelles zones a part, car ils n'ont pas d'id
        const newZones = portal.zones.filter((zone: PortalZone) => zone.id === null);
        portal.zones = portal.zones.filter((zone: PortalZone) => zone.id !== null);

        await this.portalApi.put('/', portal, {
            headers: AuthorizationHeaderBuilder.buildBearerWith(token),
        });

        if (portal.id) {
            await this.saveZonesFor(newZones, portal.id, token);
        }
    }

    public static async save(portal: SavePortalEvent, token: Token) {
        const requestData =  { name: portal.name, target: portal.target};
        const { data: response } = await this.portalApi
            .post<PortalListingData>('/', requestData, {
                headers: AuthorizationHeaderBuilder.buildBearerWith(token),
        });

        return response.id;
    }

    private static async saveZonesFor(zones: PortalZone[], portalId: Id, token: Token) {
        await this.zoneApi.post(`/${portalId}`, zones, {
            headers: AuthorizationHeaderBuilder.buildBearerWith(token),
        });
    }

    public static async getZonesForUser(token: Token) {
        const {data: response} =  await this.userApi.get(`/zones`, {
            headers: AuthorizationHeaderBuilder.buildBearerWith(token),
        });
        return response;
    }

    public static async getPortalsFor(searchCriteria: PortalSearchCriteria, token: Token) {
        const searchParams = this.buildSearchParamFrom(searchCriteria);
        const { data: response } = await this.portalApi
            .get<PortalListingResponse>(`/?search=${searchParams}&page=${searchCriteria.page}`, {
                headers: AuthorizationHeaderBuilder.buildBearerWith(token),
            });

        return { portals: response._embedded.portals, pageCount: response.page.totalPages };
    }

    private static buildSearchParamFrom(searchCriteria: PortalSearchCriteria) {
        return  `${searchCriteria.name ? 'name:'.concat(searchCriteria.name) : ''},` +
                `${searchCriteria.target && searchCriteria.target !== 'Tous' ?
                    'target:'.concat(searchCriteria.target) : ''}`;
    }
}
